
document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "profiles" && $("body").data("user_role") == "client") {
        
        let modal_car;
        if (document.getElementById("modal-car") != null) {
            modal_car = new bootstrap.Modal(document.getElementById("modal-car"), {backdrop: 'static', keyboard: false});
        }

        $('#user-phone').mask('(216) 99 999999');

        $("#user-phone").on("click", function() {
            let tel = document.getElementById("user-phone");
            tel.focus()
            tel.setSelectionRange(6, 6);
        })

        let car_id;
        let brand_id;
        let model_id;

        let table_cars = $('#table-cars').DataTable({
            "processing": true,
            "serverSide": true,
            "ajax": {
                "url": $('#table-cars').data('source'),
                data: function(e) {
                    e.user_id = $("#user_id").val()
                }
            },
            "pagingType": "full_numbers",
            "columns": [
                {"data": "brand"},
                {"data": "model"},
                {"data": "car_number"},
                {"data": "id",
                    "render": function(data, display, record) {
                        let destroy = `<a href="javascript:void(0)" class="btn-destroy-car" data-car_id="${record.id}" data-brand="${record.brand}" data-name="${record.model}(${record.car_number})" title="Retirer" style="color:red"><i class="fa-solid fa-trash" aria-hidden="true"></i></a>`
                        if (record.has_appointments == "true")
                            destroy = ""
                        return `<a href="javascript:void(0)" class="btn-edit-car" data-has_appointments='${record.has_appointments}' data-brand_id='${record.brand_id}' data-model_id='${record.model_id}' data-vin='${record.vin}' data-car_number='${record.car_number}' data-car_id="${record.id}" title="Voir"><i class="fa-solid fa-pen" aria-hidden="true"></i></a>
                                ${destroy}`
                    }
                }
            ],
            createdRow: function (row, data, index) {
                $(row).attr("data-element_id", data.id)
                
                $(document, ".btn-edit-car").unbind('click');
                
                $(document).on("click", ".btn-edit-car", function() {
                    $(".spinner-border").removeClass("d-none")

                    car_id = $(this).attr("data-car_id")
                    brand_id = $(this).attr("data-brand_id")
                    model_id = $(this).attr("data-model_id")
                    let car_number = $(this).attr("data-car_number")
                    let vin = $(this).attr("data-vin")
                    let has_appointments = $(this).attr("data-has_appointments")

                    $("#car-model").attr("disabled", true)
                    
                    fetch(`/clients/brand-models/${brand_id}`, {
                        method: 'GET',
                        mode: 'cors',
                        cache: 'default',
                    }).then(function(response) {
                        response.json().then(function(data) {
                            $(".spinner-border").addClass("d-none")

                            $("#car-model").html("")
                            $("#car-model").append("<option selected disabled>Selectionner le modèle</option>")
                            
                            $("#car-model").attr("disabled", false)
                            
                            jQuery.each(data, function (i, val) {
                                $("#car-model").append(`<option value='${val.id}'>${val.name}</option>`)
                            });
        
                            $("#car-brand").val(brand_id)
                            $("#car-model").val(model_id)


                            if (has_appointments == "true") {
                                $("#btn-save-car").addClass("d-none")
                                $("#car-brand").attr("disabled", true)
                                $("#car-model").attr("disabled", true)
                                $("#car-car_number").attr("disabled", true)
                                $("#car-vin").attr("disabled", true)
                            }
                            else {
                                $("#btn-save-car").removeClass("d-none")
                                $("#car-brand").attr("disabled", false)
                                $("#car-model").attr("disabled", false)
                                $("#car-car_number").attr("disabled", false)
                                $("#car-vin").attr("disabled", false)
                            }
                        })
                    });


                    $("#car-car_number").val(car_number)
                    $("#car-vin").val(vin)

                    
                    modal_car.show()
                })

                $(document).on("click", ".btn-destroy-car", function() {
                    let car_id = $(this).attr("data-car_id")
                    let name = $(this).attr("data-name")
                    let brand = $(this).attr("data-brand")
                    
                    destroy_car(car_id, name, brand)
                })
            },
            "initComplete":function( settings, json){
                // $(".btn-edit-car").on("click", function() {
                //     car_id = $(this).attr("data-car_id")
                //     $("#car-model").val($(`#table-cars tr[data-element_id='${car_id}'] td:nth-child(2)`).text())
                //     $("#car-car_number").val($(`#table-cars tr[data-element_id='${car_id}'] td:nth-child(1)`).text())

                //     modal_car.show()
                // })

               
            },
            language: {
                url: '/datatable-fr-FR.json',
            },
            "bPaginate": false,
            "bFilter": true,
            "bInfo": false,
            "bLengthChange": true,
            "searching": false,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'order' : [[1,'desc']],
            "columnDefs": [
                {
                    "className": "dt-center", "targets": [3],
                    "width": '15%', targets: 0,
                    "width": '20%', targets: 1,
                    "width": '20%', targets: 2,
                    "width": '20%', targets: 3
                }
            ],
        });

        
        $("#btn_add_car").on("click", function() {
            let nbre = $(".car_number").length
            $("#total_cars").val(nbre+1)
            $(".cars").append(`
                <div class='row mb-2'>
                    <div class='col-md-6 col-sm-6'>
                        <div class="form-floating">
                            <input type="hidden" name="user[car_id_${nbre+1}]" value="">
                            <input type='text' name='user[car_number_${nbre+1}]' data-id="" id='user-car_number_${nbre+1}' value='' class='form-control car_number needs-validation'>
                            <label>Matricule véhicule ${nbre+1}*</label>
                        </div>
                    </div>
                </div>
            `)

            $(".btn-delete-car").on("click", function() {
                let car_id = $(this).attr("data-car_id")
    
                if (car_id == "") {
                    $(this).parent().parent().remove()
                }
            })
        })

        $(".btn-active-car").on("click", function() {
            let car_id = $(this).attr("data-car_id")
            let is_active = $(this).attr("data-is_active")
            let title = "Activer?"
            if (is_active == 'true') {
                title = 'Désactiver?'
                new_state = false
            }
            else {
                new_state = true
            }

            Swal.fire({
                title: title,
                text: `Voulez-vous vraiment ${title.toLowerCase().replace('?', '')} ce véhicule?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Annuler',
                confirmButtonText: 'Oui',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                
                preConfirm: () => {
                    return fetch(`/clients/active-car/${car_id}/${new_state}`, {
                        method: 'PATCH',
                        mode: 'cors',
                        cache: 'default',
                        headers: {
                            'Accept': 'application/json, text/plain, */*',
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                    }).then(function(response) {
                        if (response.ok) {
                            $(`.btn-active-car[data-car_id=${car_id}]`).attr("data-is_active", new_state)
                            if (new_state == false)
                                $(`input[data-id=${car_id}]`).css("background", "#ffe8e8")
                            else
                                $(`input[data-id=${car_id}]`).css("background", "white")

                            const toastLiveExample = document.getElementById('liveToast')
                            const toastBootstrap = bootstrap.Toast.getOrCreateInstance(toastLiveExample)
                            if (is_active == 'true')
                                $(".toast-body").text("Véhicule desactivé!")
                            else
                                $(".toast-body").text("Véhicule activé!")
                            

                            toastBootstrap.show()
                        }
                    })
                }
            }).then((result) => {})
            
        })

        $(".btn-delete-car").on("click", function() {
            let car_id = $(this).attr("data-car_id")

            if (car_id == "") {
                $(this).parent().parent().remove()
            }
            else {
                Swal.fire({
                    title: "Supprimer",
                    text: `Voulez-vous vraiment supprimer ce véhicule?`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Annuler',
                    confirmButtonText: 'Oui',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    showLoaderOnConfirm: true,
                    
                    preConfirm: () => {
                        return fetch(`/clients/destroy-car/${car_id}`, {
                            method: 'DELETE',
                            mode: 'cors',
                            cache: 'default',
                            headers: {
                                'Accept': 'application/json, text/plain, */*',
                                'Content-Type': 'application/x-www-form-urlencoded'
                            },
                        }).then(function(response) {
                            if (response.ok) {
                                $(`.car_${car_id}`).remove()

                                const toastLiveExample = document.getElementById('liveToast')
                                const toastBootstrap = bootstrap.Toast.getOrCreateInstance(toastLiveExample)
                                $(".toast-body").text("Véhicule supprimé!")
                                toastBootstrap.show()
                            }
                        })
                    }
                }).then((result) => {})
            }
        })

        $("#btn-new-car").on("click", function() {
            //modal_car = new bootstrap.Modal(document.getElementById("modal-car"), {backdrop: 'static', keyboard: false});
            modal_car.show()
        })

        document.getElementById("modal-car").addEventListener('hidden.bs.modal', function (event) {
            $("#modal-car input").val("")
            car_id = null
            brand_id = null;
            model_id = null;

            $("#btn-save-car").removeClass("d-none")
            $("#car-brand").attr("disabled", false)
            $("#car-model").attr("disabled", false)
            $("#car-car_number").attr("disabled", false)
            $("#car-vin").attr("disabled", false)
            $(".alert-zone").addClass("d-none")
            $(".alert-zone .alert").text("")
        })

        $("#btn-save-profil").on("click", function() {
            $(".error-profil").addClass("d-none")
            $(".alert-profil").addClass("d-none")

            update_profil()
        })

        $("#btn-save-password").on("click", function() {
            $(".error-password").addClass("d-none")
            $(".alert-password").addClass("d-none")

            change_password()
        })

        $("#btn-save-car").on('click', function(e) {
            const forms = document.querySelectorAll('#form-car .needs-validation')
            let isValid = true
            
            Array.prototype.slice.call(forms).forEach(function (form) {
                if (form.value == "") {
                    form.classList.add('is-invalid')
                    isValid = false
                    event.preventDefault()
                    event.stopPropagation()
                }

                form.classList.add('was-validated')
            }, false)

            if (!isValid) {
                return
            }

            if ($("#car-vin").val().trim() != "" && $("#car-car_number").val().trim() != "") {
                $(".alert-zone").addClass("d-none")
                $(".alert-zone .alert").text("")

                let formData = new FormData();
                formData.append('brand_model_id', $("#car-model").val());
                formData.append('model', $("#car-model option:selected").text());
                formData.append('car_number', $("#car-car_number").val());
                formData.append('vin', $("#car-vin").val());

                $("#btn-cancel-car").attr("disabled", true)
                $("#btn-save-car").attr("disabled", true)
                $("#btn-save-car .spinner-border ").removeClass("d-none")

                let url = "/profile/create-car"
                let method = "POST"

                if (car_id != null) {
                    url = `/profile/update-car/${car_id}`
                    method = "PUT"
                }

                fetch(`${url}`, {
                    method: method,
                    mode: 'cors',
                    cache: 'default',
                    body: formData,
                }).then(function(response) {
                    if (response.ok) {
                        response.json().then(function(data) {

                            table_cars.ajax.reload()
                            
                            $("#btn-cancel-car").attr("disabled", false)
                            $("#btn-save-car").attr("disabled", false)
                            $("#btn-save-car .spinner-border ").addClass("d-none")

                            const toastLiveExample = document.getElementById('liveToast')
                            const toastBootstrap = bootstrap.Toast.getOrCreateInstance(toastLiveExample)
                            $(".toast-body").text("Véhicule Ajouté!")
                            toastBootstrap.show()

                            //modal_car = new bootstrap.Modal(document.getElementById("modal-car"), {backdrop: 'static', keyboard: false});
                            modal_car.hide()
                        })
                    }
                    else {
                        response.json().then(function(data) {

                            $("#btn-cancel-car").attr("disabled", false)
                            $("#btn-save-car").attr("disabled", false)
                            $("#btn-save-car .spinner-border ").addClass("d-none")

                            $(".alert-zone").removeClass("d-none")
                            $(".alert-zone .alert").text(data.error.join(', '))
                        })
                    }
                })
            }
        })

        $("#car-brand").on("change", function() {

            $(".spinner-border").removeClass("d-none")

            brand_id = $(this).val()
            
            fetch(`/clients/brand-models/${brand_id}`, {
                method: 'GET',
                mode: 'cors',
                cache: 'default',
            }).then(function(response) {
                response.json().then(function(data) {
                    $(".spinner-border").addClass("d-none")

                    $("#car-model").html("")
                    $("#car-model").append("<option selected disabled>Selectionner le modèle</option>")

                    $("#car-model").attr("disabled", false)

                    jQuery.each(data, function (i, val) {
                        $("#car-model").append(`<option value='${val.id}'>${val.name}</option>`)
                    });
                })
            });
        })

        $("#car-vin").on("change", function() {
            let vin = $(this).val()

            checkVin(vin)
        })

        function checkVin(vin) {
            if (isNaN(vin.slice(-6, 17)) == true) {
                $(".new_car_alert-zone").removeClass("d-none")
                $(".new_car_alert-zone .alert").text("Numéro de châssis est invalide!")

                return false
            }
            else {
                $(".new_car_alert-zone").addClass("d-none")
                $(".new_car_alert-zone .alert").text("")

                return true
            }
        }

        function update_profil() {
            if ($("#user-full_name").val().trim() != "" && $("#user-phone").val().trim() != "") {
                let formData = new FormData();
                formData.append('user[full_name]', $("#user-full_name").val());
                formData.append('user[email]', $("#user-email").val());
                formData.append('user[phone]', $("#user-phone").val());
               
                $("#btn-save-profil").attr("disabled", true)
                $("#btn-save-profil .spinner-border ").removeClass("d-none")

                let url = "/profile"
                let method = "POST"

                fetch(`/profile`, {
                    method: 'PUT',
                    mode: 'cors',
                    cache: 'default',
                    body: formData,
                }).then(function(response) {
                    if (response.ok) {
                        response.json().then(function(data) {
                            $("#btn-save-profil").attr("disabled", false)
                            $("#btn-save-profil .spinner-border ").addClass("d-none")

                            $(".alert-profil").removeClass("d-none")
                            $(".alert-profil").text("Profil mis à jour!")
                        })
                    }
                    else {
                        response.json().then(function(data) {
                            $("#btn-save-profil").attr("disabled", false)
                            $("#btn-save-profil .spinner-border ").addClass("d-none")

                            $(".error-profil").removeClass("d-none")
                            $(".error-profil").text(data.error.join(', '))

                        })
                    }
                })
            }
        }

        function change_password() {
            if ($("#user-password").val().trim() != "" && $("#user-confirmation_password").val().trim() != "") {
                if ($("#user-password").val() == $("#user-confirmation_password").val()) {
                    let formData = new FormData();
                    formData.append('user[password]', $("#user-password").val());
                    formData.append('user[password_actual]', $("#user-password_actual").val());

                    Swal.fire({
                        title: 'Mot de passe?',
                        text: "Voulez-vous vraiment changer votre mot de passe?",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#a7a7a7',
                        cancelButtonText: 'Annuler',
                        confirmButtonText: 'Oui',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        showLoaderOnConfirm: true,
                        preConfirm: () => {
                            return fetch(`/profile/change-password`, {
                                method: 'PUT',
                                mode: 'cors',
                                cache: 'default',
                                body: formData,
                            }).then(function(response) {
                                if (response.ok) {
                                    response.json().then(function(data) {
                                        $("#btn-save-password").attr("disabled", false)
                                        $("#btn-save-password .spinner-border ").addClass("d-none")
            
                                        $(".alert-password").removeClass("d-none")
                                        $(".alert-password").text("Mot de passe mis à jour")
                                    })
                                }
                                else {
                                    response.json().then(function(data) {
                                        $("#btn-save-password").attr("disabled", false)
                                        $("#btn-save-password .spinner-border ").addClass("d-none")

                                        $(".error-password").removeClass("d-none")
                                        $(".error-password").text(data.error.join(', '))
                                    })
                                }
                            })
                        }
                    })
                }
                else {
                    $(".error-password").removeClass("d-none")
                    $(".error-password").text("Confirmation mot de passe est incorrecte")
                }
            }
            else {
                $(".error-password").removeClass("d-none")
                $(".error-password").text("Confirmation mot de passe est incorrecte")
            }
        }

        function destroy_car(car_id, name, brand) {
            Swal.fire({
                title: 'Retirer?',
                text: `Voulez-vous vraiment retirer ce véhicule? ${brand} ${name}`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#a7a7a7',
                cancelButtonText: 'Annuler',
                confirmButtonText: 'Oui',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return fetch(`/profile/destroy-car/${car_id}`, {
                        method: 'DELETE',
                        mode: 'cors',
                        cache: 'default',
                        headers: {
                            'Accept': 'application/json, text/plain, */*',
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                    }).then(function(response) {
                       
                        table_cars.ajax.reload()

                        const toastLiveExample = document.getElementById('liveToast')
                            const toastBootstrap = bootstrap.Toast.getOrCreateInstance(toastLiveExample)
                            $(".toast-body").text("Véhicule retiré!")
                            toastBootstrap.show()
                    })
                }
            }).then((result) => {})
        }
    }
})