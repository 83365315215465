document.addEventListener("turbolinks:load", function() {
    if ($("body").data("user_role") == "super_admin" || $("body").data("user_role") == "admin") {
        function getSolde() {
            $(".current_solde .badge").html(`
                <div class="spinner-grow spinner-grow-sm pt-1" role="status">
                    <span class="visually-hidden">Loading...</span>
                    </div>    
            `)
            fetch(`/settings/solde/`, {
                method: 'GET',
                mode: 'cors',
                cache: 'default',
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        if (parseFloat(data.solde) != NaN) {
                            if ( parseFloat(data.solde) >= 50) {
                                $(".current_solde").html(`Solde SMS: <span id="btn_refresh_solde" class='badge bg-success' style='font-size: 12px;'>${data.solde} TND</span>`)
                            }
                            else{
                                $(".current_solde").html(`Solde SMS: <span id="btn_refresh_solde" class='badge bg-danger' style='font-size: 12px;'>${data.solde} TND</span>`)
                            }

                        }
                        else {
                            $(".current_solde").text('-')
                        }
                    })
                }
            })
        }

        $(document).on("click", "#btn_refresh_solde", function() {
            getSolde()
        })
    }

    if($("body").data("controller") == "settings") {
        const modal_service = new bootstrap.Modal(document.getElementById("modal-service"), {backdrop: 'static', keyboard: false});
        const modal_brand = new bootstrap.Modal(document.getElementById("modal-brand"), {backdrop: 'static', keyboard: false});
        const modal_model = new bootstrap.Modal(document.getElementById("modal-model"), {backdrop: 'static', keyboard: false});

        let service_id;
        let brand_id;
        let model_id;
        let method = 'create'
        let method_brand = 'create'
        let method_model = 'create'

        table = $('#table-equipe').DataTable({
            "bPaginate": false,
            "bFilter": true,
            "bInfo": false,
            "bLengthChange": false,
            "searching": false,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'order' : [[0,'desc']]
        });

        table_services = $('#table-services').DataTable({
            "bPaginate": false,
            "bFilter": true,
            "bInfo": false,
            "bLengthChange": false,
            "searching": false,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            "columnDefs": [
                {"className": "dt-center", "targets": [1, 2]}
            ],
            'order' : [[0,'desc']]
        });

        let table_brands = $('#table-brands').DataTable({
            responsive: true,
            "processing": true,
            "serverSide": true,
            "ajax": {
                "url": $('#table-brands').data('source'),
                data: function(e) {
                    //e.service_id = $("#filter-rate_services").val()
                }
            },
            "pagingType": "full_numbers",
            "columns": [
                {"data": "brand"},
                {"data": "active"},
                {"data": "id",
                    "render": function(data, display, record) {
                        let del = `<a href="javascript:" class="btn-delete-brand" data-id='${record.id}' data-brand='${record.name}' title="Supprimer"><i class="fa-solid fa-trash" aria-hidden="true"></i></a>`

                        if (record.has_models == "true")
                            del = ""

                        return `<a href="javascript:" class="btn-edit-brand" data-id='${record.id}' title="Modifier"><i class="fa-solid fa-pen" aria-hidden="true"></i></a>
                            ${del}`
                    }
                }
            ],
            createdRow: function (row, data, index) {
                $(row).attr("data-element_id", data.id)
            },
            "initComplete":function( settings, json){
                $(document).on("click", ".btn-edit-brand", function() {
                    method_brand = "update"
                    brand_id = $(this).attr("data-id")

                    getBrand(brand_id)
                })
                
                $(document).on("click", ".btn-delete-brand", function() {
                    let brand_id = $(this).attr("data-id")
                    let brand = $(this).attr("data-brand")
        
                    deleteBrand(brand_id, brand)
                })
            },
            "columnDefs": [{
                "width": '75%', targets: 0,
                "width": '10%', targets: 1,
                "width": '15%', targets: 2,
                "targets": 2, "orderable": false,
                "createdCell": function (td, cellData, rowData, row, col) 
                {
                    $(td).css('padding', '3px')
                }
            }],
            "bPaginate": false,
            "bFilter": true,
            "bInfo": true,
            "bLengthChange": false,
            "searching": true,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "pageLength": 10,
            scrollY: '400px',
            language: {
                url: '/datatable-fr-FR.json',
            },
            "bAutoWidth": false,
            'order' : [[0,'asc']]
        });
        
        let table_models = $('#table-models').DataTable({
            responsive: true,
            "processing": true,
            "serverSide": true,
            "ajax": {
                "url": $('#table-models').data('source'),
                data: function(e) {
                    //e.service_id = $("#filter-rate_services").val()
                }
            },
            "pagingType": "full_numbers",
            "columns": [
                {"data": "brand"},
                {"data": "name"},
                {"data": "active"},
                {"data": "id",
                    "render": function(data, display, record) {
                        let del = `<a href="javascript:" class="btn-delete-model" data-id='${record.id}' data-name='${record.name}' title="Supprimer"><i class="fa-solid fa-trash" aria-hidden="true"></i></a>`

                        if (record.has_cars == "true")
                            del = ''

                        return `<a href="javascript:" class="btn-edit-model" data-has_appointments='${record.has_appointments}' data-id='${record.id}' data-name='${record.name}' title="Modifier"><i class="fa-solid fa-pen" aria-hidden="true"></i></a>
                                ${del}`
                    }
                }
            ],
            createdRow: function (row, data, index) {
                $(row).attr("data-element_id", data.id)
            },
            "initComplete":function( settings, json){
                $(document).on("click", ".btn-edit-model", function() {
                    method_model = "update"
                    model_id = $(this).attr("data-id")
                    let has_appointments = $(this).attr("data-has_appointments")

                    getModel(model_id, has_appointments)

                })
                
                $(document).on("click", ".btn-delete-model", function() {
                    let model_id = $(this).attr("data-id")
                    let name = $(this).attr("data-name")
        
                    deleteModel(model_id, name)
                })

            },
            "columnDefs": [{
                "width": '15%', targets: 2,
                "width": '15%', targets: 3,
                "targets": 3, "orderable": false,
                "createdCell": function (td, cellData, rowData, row, col) 
                {
                    $(td).css('padding', '3px')
                }
            }],
            "bPaginate": false,
            "bFilter": true,
            "bInfo": true,
            "bLengthChange": false,
            "searching": true,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "pageLength": 10,
            scrollY: '400px',
            language: {
                url: '/datatable-fr-FR.json',
            },
            "bAutoWidth": false,
            'order' : [[0,'asc']]
        });

        $('#btn-new_service').on("click", function(e) {
            modal_service.show()
        })

        $('#btn-new_brand').on("click", function(e) {
            method_brand = "create"

            modal_brand.show()
        })

        $('#btn-new_model').on("click", function(e) {
            method_model = "create"

            modal_model.show()
        })

        $("#btn-save-brand").on("click", function() {
            const forms = document.querySelectorAll('#modal-brand .needs-validation')
            let isValid = true

            $(".alert-zone").addClass("d-none")
            $(".alert-zone .alert").html("")
            
            Array.prototype.slice.call(forms).forEach(function (form) {
                if (form.value == "") {
                    form.classList.add('is-invalid')
                    isValid = false
                    event.preventDefault()
                    event.stopPropagation()
                }

                form.classList.add('was-validated')
            }, false)

            if (isValid) {
                if(method_brand == "create") {
                    createBrand()
                }
                else {
                    updateBrand(brand_id)
                }
            }
        })
        
        $("#btn-save-model").on("click", function() {
            const forms = document.querySelectorAll('#modal-model .needs-validation')
            let isValid = true

            $(".alert-zone").addClass("d-none")
            $(".alert-zone .alert").html("")

            Array.prototype.slice.call(forms).forEach(function (form) {
                if (form.value == "") {
                    form.classList.add('is-invalid')
                    isValid = false
                    event.preventDefault()
                    event.stopPropagation()
                }

                form.classList.add('was-validated')
            }, false)

            if (isValid) {
                if(method_model == "create") {
                    createModel()
                }
                else {
                    updateModel(model_id)
                }
            }
        })

        document.addEventListener("turbolinks:before-cache", function() {


        })


        $(document).on("click", ".btn-edit-service", function(e) {
            service_id = $(e.currentTarget).parent().parent().data('element_id')
            method = 'edit'

            getService()

            modal_service.show()
        })

        

        document.getElementById("modal-service").addEventListener('hidden.bs.modal', function (event) {
            $(".service-btn_delete_image").remove()
            $("#service-name").val("")
            $("#service-slug").val("")
            $("#service-duration").val('1')
            $("#service-min_time_booking").val('')
            $("#service-status").attr('checked', false)
            $("#service-description").val('')
            $(".img img").remove()
            $("#service-image").val("")
            
            $('input, button, select, textarea').attr('disabled', false)

            method = 'create'
        })

        document.getElementById("modal-brand").addEventListener('hidden.bs.modal', function (event) {
            $("#brand-name").val("")
            $("#brand-active").prop("checked", false)
            $(".alert-zone").addClass("d-none")
            $(".alert-zone .alert").html("")
            $('input, button, select, textarea').attr('disabled', false)

            method_brand = 'create'
        })

        document.getElementById("modal-model").addEventListener('hidden.bs.modal', function (event) {
            $("#model-name").val("")
            $("#model-brand").val("")
            $("#model-active").prop("checked", false)
            $("#model-brand").attr("disabled", false)
            $("#model-name").attr("disabled", false)

            $(".alert-zone").addClass("d-none")
            $(".alert-zone .alert").html("")
            $('input, button, select, textarea').attr('disabled', false)

            method_model = 'create'
        })

        $('#form-service').on('submit', function(event) {
            const forms = document.querySelectorAll('#modal-service .needs-validation')
            let isValid = true
            
            event.preventDefault()
            let image = event.target.uploadFile.files[0]

            Array.prototype.slice.call(forms).forEach(function (form) {
                if (form.value == "") {
                    form.classList.add('is-invalid')
                    isValid = false
                    event.preventDefault()
                    event.stopPropagation()
                }

                form.classList.add('was-validated')
            }, false)

            if (isValid) {

                $('input, button, select, textarea').attr('disabled', true)

                if (method == 'create')
                    createService(image)
                else if(method == 'edit')
                    updateService(image)
            }
        })

        $("#table-services").on('click', ".btn-delete-service", function(e) {
            deleteService($(e.currentTarget).parent().parent().data('element_id'))
        })

        $("#btn-delete-background").on("click", function() {
            Swal.fire({
                title: 'Supprimer?',
                text: "Voulez-vous vraiment supprimer l'arrière-paln?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Annuler',
                confirmButtonText: 'Oui',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return fetch(`/settings/background`, {
                        method: 'DELETE',
                        mode: 'cors',
                        cache: 'default',
                        headers: {
                            'Accept': 'application/json, text/plain, */*',
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                    }).then(function(response) {
                        if (response.ok) {
                            response.json().then(function(data) {
                                
                              window.location.reload()
                            })
                        }
                    })
                }
            }).then((result) => {})
        })

        $("#form-logo").on("submit", function(event) {
            const forms = document.querySelectorAll('#form-logo .needs-validation')
            let isValid = true

            event.preventDefault()

            let image = event.target.uploadLogo.files[0]

            Array.prototype.slice.call(forms).forEach(function (form) {
                if (form.value == "") {
                    form.classList.add('is-invalid')
                    isValid = false
                    event.preventDefault()
                    event.stopPropagation()
                }

                form.classList.add('was-validated')
            }, false)

            if (isValid) {

                $('input, button, select, textarea').attr('disabled', true)
                $("#btn-save-logo .spinner-border").removeClass("d-none")
                
                let formData = new FormData();
                formData.append('image', image);

                fetch(`/settings/logo`, {
                    method: 'PUT',
                    mode: 'cors',
                    cache: 'default',
                    body: formData,
                }).then(function(response) {
                    if (response.ok) {
                        response.json().then(function(data) {

                            $('input, button, select, textarea').attr('disabled', false)
                            $("#btn-save-logo .spinner-border").addClass("d-none")

                            if ($("#logo-thumb").length != 0) {
                                $("#logo-thumb").attr("src", data.image.url)
                            }
                            else {
                                $(".no_logo").remove()
                                $(".img_logo").append(`<img src="${data.image.url}" class="p-3" id="logo-thumb" width="150" >`)
                            }
                        })
                    }
                })
            }
        })

        $("#form-contact").on("submit", function(event) {
            const forms = document.querySelectorAll('#form-contact .needs-validation')
            let isValid = true

            $(".alert-error-contact").addClass("d-none")

            event.preventDefault()

            Array.prototype.slice.call(forms).forEach(function (form) {
                if (form.value == "") {
                    form.classList.add('is-invalid')
                    isValid = false
                    event.preventDefault()
                    event.stopPropagation()
                }

                form.classList.add('was-validated')
            }, false)

            if (isValid) {

                $('input, button, select, textarea').attr('disabled', true)
                $("#btn-save-contact .spinner-border").removeClass("d-none")
                
                let formData = new FormData();
                formData.append('email', $("#contact-email").val());
                formData.append('phone', $("#contact-phone").val());

                fetch(`/settings/contacts`, {
                    method: 'PUT',
                    mode: 'cors',
                    cache: 'default',
                    body: formData,
                }).then(function(response) {
                    if (response.ok) {
                        response.json().then(function(data) {
                            $('input, button, select, textarea').attr('disabled', false)
                            $("#btn-save-contact .spinner-border").addClass("d-none")

                            $(".alert-contact").removeClass("d-none")
                            $(".alert-contact").text("Enregistré!")

                        })
                    }
                })
            }
        })


        $("#form-background").on("submit", function(event) {
            const forms = document.querySelectorAll('#form-background .needs-validation')
            let isValid = true

            $(".alert-error-background").addClass("d-none")

            event.preventDefault()

            let image = event.target.uploadBackground.files[0]

            if(image.size > 500000) {
                $(".alert-error-background").removeClass("d-none")
                $(".alert-error-background").text("La taille du fichier doit être inférieure à 500 ko")

                this.value = "";
                isValid = false;
            }
            
            Array.prototype.slice.call(forms).forEach(function (form) {
                if (form.value == "") {
                    form.classList.add('is-invalid')
                    isValid = false
                    event.preventDefault()
                    event.stopPropagation()
                }

                form.classList.add('was-validated')
            }, false)

            if (isValid) {

                $('input, button, select, textarea').attr('disabled', true)
                $("#btn-save-logo .spinner-border").removeClass("d-none")
                
                let formData = new FormData();
                formData.append('image', image);

                fetch(`/settings/background`, {
                    method: 'PUT',
                    mode: 'cors',
                    cache: 'default',
                    body: formData,
                }).then(function(response) {
                    if (response.ok) {
                        response.json().then(function(data) {
                            $('input, button, select, textarea').attr('disabled', false)
                            $("#btn-save-logo .spinner-border").addClass("d-none")

                            if ($("#background-thumb").length != 0) {
                                $("#background-thumb").attr("src", data.image.url)
                            }
                            else {
                                $(".no_background").remove()
                                $(".img_background").append(`<img src="${data.image.url}" class="p-3" id="background-thumb" width="150" >`)
                            }

                            window.location.reload()
                        })
                    }
                })
            }
        })

        $("#form-sms").on("submit", function(event) {
            const forms = document.querySelectorAll('#form-sms .needs-validation')
            let isValid = true

            $(".alert-sms").addClass('d-none')

            event.preventDefault()

            let formData = new FormData();
                
            Array.prototype.slice.call(forms).forEach(function (form) {
                if (form.value == "") {
                    form.classList.add('is-invalid')
                    isValid = false
                    event.preventDefault()
                    event.stopPropagation()
                }
                else {
                    formData.append(`sms[${form.name}[name]]`, form.value);
                    formData.append(`sms[${form.name}[active]]`, $(`#${form.name}_active`).prop("checked"));
                }

                form.classList.add('was-validated')
            }, false)

            if (isValid) {
                $("#btn-save-sms .spinner-border").removeClass("d-none")

                $('input, button, select, textarea').attr('disabled', true)

                fetch(`/settings/sms`, {
                    method: 'PUT',
                    mode: 'cors',
                    cache: 'default',
                    body: formData,
                }).then(function(response) {
                    if (response.ok) {
                        response.json().then(function(data) {
                            $('input, button, select, textarea').attr('disabled', false)
                            $("#btn-save-sms .spinner-border").addClass("d-none")

                            $(".alert-sms").removeClass('d-none')
                            $(".alert-sms").text("Enregistré!")
                        })
                    }
                })
            }
        })
        
        $("#form-sms_key").on("submit", function(event) {
            const forms = document.querySelectorAll('#form-sms_key .needs-validation')
            let isValid = true

            $(".alert-sms").addClass('d-none')

            event.preventDefault()

            let formData = new FormData();
                
            Array.prototype.slice.call(forms).forEach(function (form) {
                if (form.value == "") {
                    form.classList.add('is-invalid')
                    isValid = false
                    event.preventDefault()
                    event.stopPropagation()
                }
                else {
                    formData.append(`sms[${form.name}]`, form.value);
                    formData.append(`sms[sms_deactivate]`, $("#sms_deactivate").prop("checked"));
                }

                form.classList.add('was-validated')
            }, false)

            if (isValid) {
                $("#btn-save-sms .spinner-border").removeClass("d-none")

                $('input, button, select, textarea').attr('disabled', true)

                fetch(`/settings/sms_key`, {
                    method: 'PUT',
                    mode: 'cors',
                    cache: 'default',
                    body: formData,
                }).then(function(response) {
                    if (response.ok) {
                        response.json().then(function(data) {
                            $('input, button, select, textarea').attr('disabled', false)
                            $("#btn-save-sms_key .spinner-border").addClass("d-none")

                            $(".alert-sms_key").removeClass('d-none')
                            $(".alert-sms_key").text("Enregistré!")
                        })
                    }
                })
            }
        })

        $(document).on("click", "#service-delete_image", function() {
            Swal.fire({
                title: 'Supprimer?',
                text: "Voulez-vous vraiment supprimer l'image?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Annuler',
                confirmButtonText: 'Oui',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return fetch(`/services/delete-image/${service_id}`, {
                        method: 'DELETE',
                        mode: 'cors',
                        cache: 'default',
                        headers: {
                            'Accept': 'application/json, text/plain, */*',
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                    }).then(function(response) {
                        if (response.ok) {
                            response.json().then(function(data) {
                                $(".service-image_thumb").remove()
                                $(".service-btn_delete_image").remove()
                            })
                        }
                    })
                }
            }).then((result) => {})
        })

        $(document).on("click", "#btn_delete_logo", function() {
            Swal.fire({
                title: 'Supprimer?',
                text: "Voulez-vous vraiment supprimer le logo?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Annuler',
                confirmButtonText: 'Oui',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return fetch(`/settings/delete-logo`, {
                        method: 'DELETE',
                        mode: 'cors',
                        cache: 'default',
                        headers: {
                            'Accept': 'application/json, text/plain, */*',
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                    }).then(function(response) {
                        if (response.ok) {
                            response.json().then(function(data) {
                                $("#logo-thumb").remove()
                                $(".btn_delete_logo").remove()

                                $(".img_logo").append("<div class='no_logo mt-5'>Pas d'image</div>")
                            })
                        }
                    })
                }
            }).then((result) => {})
        })

        // *** FUNCTIONS
        function getService() {
            fetch(`/services/${service_id}`, {
                method: 'GET',
                mode: 'cors',
                cache: 'default',
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        $("#service-name").val(data.name)
                        $("#service-slug").val(data.slug)
                        $("#service-duration").val(data.duration)
                        $("#service-min_time_booking").val(data.min_time_prior_booking)
                        $("#service-status").prop('checked', data.active)
                        $("#service-description").val(data.description)
                        if(data.accept_auto_new_reservation == true)
                            $("#service-appointment_acceptation_yes").prop("checked", true)
                        else {
                            $("#service-appointment_acceptation_no").prop("checked", true)
                        }
                        if (data.image.url != null) {
                            $(".img").append(`<img src='${data.image.url}' class='service-image_thumb m-2' width='128'> `)
                            $(".img").append(`<div class='service-btn_delete_image'><a href="javascript:void(0)" id="service-delete_image">Supprimer l'image</a></div>`)
                        }
                        
                        if (data.slug != "entretien-périodique" && data.slug != "diagnostic-mécanique" && data.slug != "carrosserie") {
                            $("#service-slug").attr("disabled", false)
                        }
                        else {
                            $("#service-slug").attr("disabled", true)
                        }
                    })
                }
            })
        }

        function createService(image) {
            let formData = new FormData();
            formData.append('name', $("#service-name").val());
            formData.append('slug', $("#service-slug").val());
            formData.append('duration', $("#service-duration option:selected").val());
            formData.append('min_time_prior_booking', $("#service-min_time_booking option:selected").val());
            formData.append('accept_auto_new_reservation', $(`input[name='service-appointment_acceptation']:checked`).attr("data-accept"));
            formData.append('active', $("#service-status").prop('checked'));
            formData.append('description', $("#service-description").val());

            if (image != null)
                formData.append('image', image);

            fetch(`/services`, {
                method: 'POST',
                mode: 'cors',
                cache: 'default',
                body: formData,
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        let badge = data.active ? 'success' : 'warning'
                        let active = `<span class='badge bg-${badge}'>${data.active}</span>`

                        $('input, button').attr('disabled', false)
                        $("#service-name").val('')
                        $("#service-slug").val('')
                        $("#service-duration").val(data.duration)
                        $("#service-min_time_booking").val(data.min_time_prior_booking)
                        $("#service-status").prop('checked', false)
                        $("#service-description").val("")
                        
                        let newRow = table_services.row.add([
                            data.name,
                            //data.duration + "h",
                            active,
                            `<a href="javascript:" class="btn-edit-service link-primary" title="Modifier"><i class="fa-solid fa-pen" aria-hidden="true"></i></a>
                            <a href="javascript:" class="btn-delete-service link-danger" title="Supprimer"><i class="fa-solid fa-trash" aria-hidden="true"></i></a>`
                        ] ).draw( false ).node();
    
                        $(newRow).attr("data-element_id", data.id);

                        //deleteService(service_id)

                        modal_service.hide()
                    })
                }
            })
        }

        function updateService(image) {
            let formData = new FormData();
            formData.append('name', $("#service-name").val());
            formData.append('slug', $("#service-slug").val());
            formData.append('duration', $("#service-duration option:selected").val());
            formData.append('min_time_prior_booking', $("#service-min_time_booking option:selected").val());
            formData.append('accept_auto_new_reservation', $(`input[name='service-appointment_acceptation']:checked`).attr("data-accept"));
            formData.append('active', $("#service-status").prop('checked'));
            formData.append('description', $("#service-description").val());

            if (image != null)
                formData.append('image', image);

            fetch(`/services/${service_id}`, {
                method: 'PUT',
                mode: 'cors',
                cache: 'default',
                body: formData,
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        let badge = data.active ? 'success' : 'warning'
                        let active = `<span class='badge bg-${badge}'>${data.active}</span>`

                        $(`#table-services tr[data-element_id='${data.id}'] td:nth-child(1)`).text(data.name);
                       // $(`#table-services tr[data-element_id='${data.id}'] td:nth-child(2)`).text(data.duration  + "h");
                        $(`#table-services tr[data-element_id='${data.id}'] td:nth-child(2)`).html(active);

                        //deleteService(service_id)

                        modal_service.hide()
                    })
                }
            })
        }

        function deleteService(service_id) {
            Swal.fire({
                title: 'Supprimer?',
                text: "Voulez-vous vraiment supprimer ce service?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Annuler',
                confirmButtonText: 'Oui',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return fetch(`/services/${service_id}`, {
                        method: 'DELETE',
                        mode: 'cors',
                        cache: 'default',
                        headers: {
                            'Accept': 'application/json, text/plain, */*',
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                    }).then(function(response) {
                        if (response.ok) {
                            response.json().then(function(data) {
                                
                                $(`tr[data-element_id='${data.id}']`).remove()

                                modal_service.hide()
                            })
                        }
                    })
                }
            }).then((result) => {})
        }

        function getBrand(brand_id) {
            fetch(`/settings/brand/${brand_id}`, {
                method: 'GET',
                mode: 'cors',
                cache: 'default',
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        modal_brand.show()

                        $("#brand-active").prop("checked", data.active)
                        $("#brand-name").val(data.brand)
                    })
                }
            })
        }

        function createBrand() {
            let formData = new FormData();
            formData.append('active', $("#brand-active").prop("checked"));
            formData.append('brand', $("#brand-name").val());

            fetch(`/settings/brand`, {
                method: 'POST',
                mode: 'cors',
                cache: 'default',
                body: formData,
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {

                        $('input, button').attr('disabled', false)
                        $("#brand-name").val('')
                        
                        $("#model-brand").append(`<option value='${data.id}'>${data.brand}</option>`)

                        table_brands.ajax.reload()

                        const toastLiveExample = document.getElementById('liveToast')
                        const toastBootstrap = bootstrap.Toast.getOrCreateInstance(toastLiveExample)
                        $(".toast-body").text("Marque créée!")
                        toastBootstrap.show()

                        modal_brand.hide()
                    })
                }
                else {
                    response.json().then(function(data) {
                        $(".alert-zone").removeClass("d-none")
                        
                        jQuery.each(data, function (i, val) {
                            $(".alert-zone .alert").append(`${val}`)
                        });
                        
                    })
                }
            })
        }

        function updateBrand(brand_id) {
            let formData = new FormData();
            formData.append('active', $("#brand-active").prop("checked"));
            formData.append('brand', $("#brand-name").val());

            fetch(`/settings/brand/${brand_id}`, {
                method: 'PUT',
                mode: 'cors',
                cache: 'default',
                body: formData,
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {

                        $('input, button').attr('disabled', false)
                        $("#brand-name").val('')
                        
                        table_brands.ajax.reload()

                        const toastLiveExample = document.getElementById('liveToast')
                        const toastBootstrap = bootstrap.Toast.getOrCreateInstance(toastLiveExample)
                        $(".toast-body").text("Marque mis à jour!")
                        toastBootstrap.show()

                        modal_brand.hide()
                    })
                }
                else {
                    response.json().then(function(data) {
                        $(".alert-zone").removeClass("d-none")
                        
                        jQuery.each(data, function (i, val) {
                            $(".alert-zone .alert").append(`${val}`)
                        });
                        
                    })
                }
            })
        }

        function deleteBrand(brand_id, brand) {
            Swal.fire({
                title: 'Supprimer?',
                text: `Voulez-vous vraiment supprimer la marque ${brand}?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Annuler',
                confirmButtonText: 'Oui',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return fetch(`/settings/brand/${brand_id}`, {
                        method: 'DELETE',
                        mode: 'cors',
                        cache: 'default',
                        headers: {
                            'Accept': 'application/json, text/plain, */*',
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                    }).then(function(response) {
                        if (response.ok) {
                            response.json().then(function(data) {
                                table_brands.ajax.reload()

                                const toastLiveExample = document.getElementById('liveToast')
                                const toastBootstrap = bootstrap.Toast.getOrCreateInstance(toastLiveExample)
                                $(".toast-body").text("Marque supprimé!")
                                toastBootstrap.show()
                            })
                        }
                    })
                }
            }).then((result) => {})
        }

        function getModel(model_id, has_appointments) {
            fetch(`/settings/brand-model/${model_id}`, {
                method: 'GET',
                mode: 'cors',
                cache: 'default',
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        modal_model.show()

                        $("#model-active").prop("checked", data.active)
                        $("#model-brand").val(data.brand_id)
                        $("#model-name").val(data.name)

                        if (has_appointments == 'true') {
                            $("#model-brand").attr("disabled", true)
                            $("#model-name").attr("disabled", true)
                        }

                    })
                }
            })
        }

        function createModel() {

            let formData = new FormData();
            formData.append('active', $("#model-active").prop("checked"));
            formData.append('brand_id', $("#model-brand").val());
            formData.append('name', $("#model-name").val());

            fetch(`/settings/brand-model`, {
                method: 'POST',
                mode: 'cors',
                cache: 'default',
                body: formData,
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        $('input, button').attr('disabled', false)
                        $("#brand-name").val('')
                        
                        table_models.ajax.reload()

                        const toastLiveExample = document.getElementById('liveToast')
                        const toastBootstrap = bootstrap.Toast.getOrCreateInstance(toastLiveExample)
                        $(".toast-body").text("Modèle créée!")
                        toastBootstrap.show()

                        modal_model.hide()
                    })
                }
                else {
                    response.json().then(function(data) {
                        $(".alert-zone").removeClass("d-none")
                        
                        jQuery.each(data, function (i, val) {
                            $(".alert-zone .alert").append(`${val}`)
                        });
                        
                    })
                }
            })
        }

        function updateModel(model_id) {

            let formData = new FormData();
            formData.append('active', $("#model-active").prop("checked"));
            formData.append('name', $("#model-name").val());
            formData.append('brand_id', $("#model-brand").val());

            fetch(`/settings/brand-model/${model_id}`, {
                method: 'PUT',
                mode: 'cors',
                cache: 'default',
                body: formData,
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {

                        $('input, button').attr('disabled', false)
                        $("#model-brand").val('')
                        $("#model-name").val('')
                        
                        table_models.ajax.reload()

                        const toastLiveExample = document.getElementById('liveToast')
                        const toastBootstrap = bootstrap.Toast.getOrCreateInstance(toastLiveExample)
                        $(".toast-body").text("Modèle mis à jour!")
                        toastBootstrap.show()

                        modal_model.hide()
                    })
                }
                else {
                    response.json().then(function(data) {
                        $(".alert-zone").removeClass("d-none")
                        
                        jQuery.each(data, function (i, val) {
                            $(".alert-zone .alert").append(`${val}`)
                        });
                        
                    })
                }
            })
        }

        function deleteModel(model_id, name) {
            Swal.fire({
                title: 'Supprimer?',
                text: `Voulez-vous vraiment supprimer le modèle ${name}?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Annuler',
                confirmButtonText: 'Oui',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return fetch(`/settings/brand-model/${model_id}`, {
                        method: 'DELETE',
                        mode: 'cors',
                        cache: 'default',
                        headers: {
                            'Accept': 'application/json, text/plain, */*',
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                    }).then(function(response) {
                        if (response.ok) {
                            response.json().then(function(data) {
                                table_models.ajax.reload()

                                const toastLiveExample = document.getElementById('liveToast')
                                const toastBootstrap = bootstrap.Toast.getOrCreateInstance(toastLiveExample)
                                $(".toast-body").text("Modèle supprimé!")
                                toastBootstrap.show()
                            })
                        }
                    })
                }
            }).then((result) => {})
        }
    }
})