document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "home" && $("body").data("user_role") == "client"  || $("body").data("controller") == "sessions" && $("body").data("user_role") == "client"  ) {
        let modal_appointment;
        let modal_rate;
        let modal_announcement;

        if (document.getElementById("modal-see_appointment") != null) {
            modal_appointment = new bootstrap.Modal(document.getElementById("modal-see_appointment"), {backdrop: 'static', keyboard: false});
        }
        if (document.getElementById("modal-rate") != null) {
            modal_rate = new bootstrap.Modal(document.getElementById("modal-rate"), {backdrop: 'static', keyboard: false});
        }
        

        let appointment_id;
        var myRater;

        let table_alerts = $('#table-alerts').DataTable({
            "bPaginate": true,
            "bFilter": true,
            "bInfo": true,
            "bLengthChange": false,
            "searching": false,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "language": {
                "emptyTable": "Aucune alerte.",
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            language: {
                url: '/datatable-fr-FR.json',
            },
            "pageLength": 5,
            "bAutoWidth": false,
            'order' : [[0,'desc']]
        });
        
        let table_alerts_read = $('#table-alerts_read').DataTable({
            "bPaginate": true,
            "bFilter": true,
            "bInfo": false,
            "bLengthChange": false,
            "searching": false,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "language": {
                "emptyTable": "Aucune alerte.",
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            language: {
                url: '/datatable-fr-FR.json',
            },
            "pageLength": 5,
            "bAutoWidth": false,
            'order' : [[0,'desc']]
        });

        
        let table_appointment = $('#table-appointments').DataTable({
            "bPaginate": true,
            "bFilter": true,
            "bInfo": true,
            "bLengthChange": false,
            "searching": false,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "pageLength": 10,
            "bAutoWidth": false,
            'order' : [[0,'desc']],
            language: {
                url: '/datatable-fr-FR.json',
            },
            drawCallback: function () {
                $(".see_appointment").on('click', function(e) {
                    appointment_id = $(e.currentTarget).data('element_id')
                    
                    getAppointment(appointment_id)
        
                })
            },
            "scrollX": true,
            "sScrollX": "100%",
            "sScrollXInner": "100%",
            "bScrollCollapse": true
        });

        let table_remainder = $('#table-remainder').DataTable({
            "bPaginate": false,
            "bFilter": false,
            "bInfo": false,
            "bLengthChange": false,
            "searching": false,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "language": {
                "emptyTable": "Aucun rappel de service.",
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            language: {
                url: '/datatable-fr-FR.json',
            },
            "pageLength": 5,
            "bAutoWidth": false,
            'order' : [[0,'desc']]
        });

        $(".btn-alert-set-seen").on("click", function() {
            let id = $(this).data("id")
            
            Swal.fire({
                text: "Marquer cette alerte comme lue?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Annuler',
                confirmButtonText: 'Oui',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return fetch(`/appointments/alert/${id}`, {
                        method: 'PUT',
                        mode: 'cors',
                        cache: 'default',
                        headers: {
                            'Accept': 'application/json, text/plain, */*',
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                    }).then(function(response) {
                        if (response.ok) {
                            response.json().then(function(data) {
                                table_alerts.row($(`tr[data-element_id='${id}']`)).remove().draw();

                                table_alerts_read.row.add([data.libelle, '']).draw(false);
                            })
                        }
                    })
                }
            }).then((result) => {})
        })

        $(".resend_code").on("click", function() {

            Swal.fire({
                title: "Activation code",
                text: `Renvoyer le code d'activation?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Annuler',
                confirmButtonText: 'Oui',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                
                preConfirm: () => {
                    return fetch(`/clients/send-activation-code/1`, {
                        method: 'PATCH',
                        mode: 'cors',
                        cache: 'default',
                        body:  JSON.stringify({'id': 1}),
                        headers: {
                            'Accept': 'application/json, text/plain, */*',
                            'Content-Type': 'application/json'
                        }
                    }).then(function(response) {
                        if (response.ok) {
                            response.json().then(function(data) {
   
                                if (data.error == true) {
                                    Swal.fire({
                                        title: "Erreur",
                                        text: data.message,
                                        icon: "error"
                                    });
                                }
                                else {
                                    Swal.fire({
                                        title: "Envoyé!",
                                        text: "Code d'activation envoyé avec succès! Veuillez vérifier votre SMS.",
                                        icon: "success"
                                    });
                                }
                            });
                        }
                    })
                }
            }).then((result) => {})
        })
    
        
        $("#btn-delete_appointment").on('click', function(e) {
            cancel_appointment()
        })

        function cancel_appointment() {
            Swal.fire({
                title: 'Annuler?',
                text: "Voulez-vous vraiment annuler ce rendez-vous?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Annuler',
                confirmButtonText: 'Oui',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return fetch(`/appointments/${appointment_id}`, {
                        method: 'DELETE',
                        mode: 'cors',
                        cache: 'default',
                        headers: {
                            'Accept': 'application/json, text/plain, */*',
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                    }).then(function(response) {
                        if (response.ok) {
                            response.json().then(function(data) {
                                $(`tr[data-element_id='${appointment_id}']`).remove()

                                modal_appointment.hide()
                            })
                        }
                    })
                }
            }).then((result) => {})
        }

        function getAppointment(appointment_id) {
            var rater = require("rater-js");

            fetch(`/appointments/${appointment_id}`, {
                method: 'GET',
                mode: 'cors',
                cache: 'default',
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        let duration = data.duration
                        // if (data.workshop_service != null) {
                        //     duration = data.workshop_service.duration
                        // }
                        
                        $(`.appointment-${appointment_id} .indicator`).remove()
                        $('#see_appointment_id').val(data.id)
                        $("#see_service").val(`${data.service.name} (${duration} ${Pluralize( 'minute', duration )})`)
                        $("#see_date").val(moment(data.date).locale("fr").format('dddd DD MMM YYYY'))
                        //$("#see_periode").val(`${data.time} à ${parseInt(data.time.replace('h:00','')) + duration}h:00`);
                        $("#see_periode").val(`${data.time} à ${ moment(new Date(`2024-08-27 ${data.time}`)).add(duration, "minutes").format("k:mm")}`);
                        $("#see_full_name").val(data.user.full_name)
                        $("#see_phone").val(data.phone)
                        $("#see_email").val(data.email);
                        $("#see_car_number").val(`${data.brand.brand} ${data.car.model.name} (${data.car.car_number})`);
                        $("#see_km").val(data.km);
                        $("#see_notes").val(data.notes);
                        $("#see_note_client").val(data.note_client);
                        $("#see_description").val(data.description);
                        $("#see_note_intern").val(data.note_intern);
                        $("#see_state").val(data.state);

                        if (data.state == "completed" || data.state == "canceled" || data.state == "rejected") {
                            $("#btn-delete_appointment").remove()
                        }
                        else {

                            if ($("#btn-delete_appointment").length == 0 ) {
                                $("#modal-edit_appointment .modal-footer").append(`
                                    <button type="button" id="btn-delete_appointment" class="btn btn-danger"><i class="fa-solid fa-xmark"></i> Annuler ce rendez-vous</button>
                                `)
                            }

                            if ($("#btn-delete_appointment").length == 0) {
                                $("#form-appointment .modal-footer").append(`
                                    <button type="button" id="btn-delete_appointment" class="btn btn-danger"><i class="fa-solid fa-xmark" aria-hidden="true"></i> Annuler ce rendez-vous</button>
                                `)

                                $(document).on('click', "#btn-delete_appointment", function(e) {
                                    cancel_appointment()
                                })
                            }
                        }

                        if (data.service.slug == 'carrosserie') {

                            $(".carrosserie").removeClass('visually-hidden')
                        }
                        else {
                            $(".carrosserie").addClass('visually-hidden')
                        }

                        if (data.rated == true) {
                            $("#rater").html("")
                            myRater = rater({element: document.querySelector("#rater"), rateCallback: function rateCallback(rating, done) {
                                myRater.setRating(rating);
                                    done();
                                }, 
                                starSize:50,
                                step: 0.5,
                                rating: data.rating.star
                            });

                            myRater.disable()
                        }
                        else {

                            $("#rater").html("")
                            if (myRater != null) {
                                myRater.dispose()
                            }

                            $("#rater").html("Le client n'a pas encore donné son avis.")
                        }
                        
                        modal_appointment.show()
                    })
                }
            })
        }
        
        function getAppointments() {
            var rater = require("rater-js");
            
            fetch(`/appointments/my-appointments`, {
                method: 'GET',
                mode: 'cors',
                cache: 'default',
            }).then(function(response) {
                response.json().then(function(data) {
                    jQuery.each(data, function (i, val) {
                        if (val.state == "completed" && val.rated == false) {
                          
                            $(".rate-service_name").text(val.service.name);
                            $(".rate-vahicule").text(`${val.brand.brand} ${val.car.model.name}(${val.car.car_number})`);
                            $(".rate-date").text(val.date);
                            $(".rate-appointment_id").val(val.id)

                            myRater = rater({element: document.querySelector("#rating"), rateCallback: function rateCallback(rating, done) {
                                myRater.setRating(rating);
                                done();
                                }, 
                                starSize:50,
                                step: 0.5,
                            });

                            modal_rate.show()
                            return false
                        }
                    });
                })

            });

        }


        $("#btn-save_rate").on("click", function() {
            let rates = myRater.getRating();
            let note = $("#rate_noting").val();
            let appointment_id = $(".rate-appointment_id").val();

            if (rates == null) {
                rates = 0;
            }

            Swal.fire({
                text: "Valider votre note?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Annuler',
                confirmButtonText: 'Oui',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return fetch(`/appointments/rate`, {
                        method: 'POST',
                        mode: 'cors',
                        cache: 'default',
                        body: 
                            JSON.stringify({
                                'appointment_id': appointment_id,
                                'star': rates,
                                'note': note,
                            })
                        ,
                        headers: {
                            'Accept': 'application/json, text/plain, */*',
                            'Content-Type': 'application/json'
                        },
                    }).then(function(response) {
                        if (response.ok) {
                            response.json().then(function(data) {
                               
                                modal_rate.hide()

                                Swal.fire(
                                    'Envoyé!',
                                    `Merci pour votre retour!`,
                                    'success'
                                )

                            })
                        }
                    })
                }
            }).then((result) => {})
        })

        getAppointments();

        
    }

    $("#car_brand").on("change", function() {
        let brand_id = $(this).val()

        $("#car-model").attr("disabled", true)
        $(".spinner-border").removeClass("d-none")

        fetch(`/clients/brand-models/${brand_id}`, {
            method: 'GET',
            mode: 'cors',
            cache: 'default',
        }).then(function(response) {
            response.json().then(function(data) {
                $(".spinner-border").addClass("d-none")

                $("#car_model").html("")
                $("#car_model").append("<option selected disabled>Selectionner le modèle</option>")

                $("#car-model").attr("disabled", false)

                jQuery.each(data, function (i, val) {
                    $("#car_model").append(`<option value='${val.id}'>${val.name}</option>`)
                });
            })
        });
    })
    
    if($("body").data("controller") == "clients" && $("body").data("action") == "activation"){
        $(".resend_code").on("click", function() {
            Swal.fire({
                title: "Activation code",
                text: `Renvoyer le code d'activation?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Annuler',
                confirmButtonText: 'Oui',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                
                preConfirm: () => {
                    return fetch(`/clients/send-activation-code/1`, {
                        method: 'PATCH',
                        mode: 'cors',
                        cache: 'default',
                        body:  JSON.stringify({'id': 1}),
                        headers: {
                            'Accept': 'application/json, text/plain, */*',
                            'Content-Type': 'application/json'
                        }
                    }).then(function(response) {
                        if (response.ok) {
                            Swal.fire({
                                title: "Envoyé!",
                                text: "Code d'activation envoyé avec succès! Veuillez vérifier votre SMS.",
                                icon: "success"
                            });
                        }
                    })
                }
            }).then((result) => {})
        })
    }
})
